import type { FC, ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { api } from 'src/api';
import { API_BASE } from 'src/constants/api';
import { getSubdomainFromUrl } from 'src/utils/url/get-subdomain-from-url';

interface SubdomainLender {
    logo: string;
    name: string;
    isReady: boolean;
    /** If the subdomain is valid */
    okSubdomain: boolean;
}

export interface LenderContextValue {
    lender: SubdomainLender;
}

interface LenderProviderProps {
    children?: ReactNode;
}

const initialValues: SubdomainLender = {
    isReady: false,
    name: '',
    logo: null,
    okSubdomain: false
};


export const SubdomainLenderContext = createContext<SubdomainLender>(initialValues);

export const SubdomainLenderProvider: FC<LenderProviderProps> = (props) => {
    const { children } = props;
    const [lender, setLender] = useState<SubdomainLender>(initialValues);

    useEffect(() => {
        (async () => {
            let logo = '/static/mysherpas_logo_black.png?v=29';
            let lenderName = 'mysherpas';
            let okSubdomain = false;
            try {
                const result = await api.getCompanyDetails(getSubdomainFromUrl(document.location.href))
                if (result) {
                    lenderName = result.name;
                    okSubdomain = true;
                }
                // Not using the api get to avoid the fetch problem
                const response = await fetch(`${API_BASE}/v1/lenders/subdomainLogoStub`);
                if (response.status == 200) {
                    logo = `${API_BASE}/v1/lenders/subdomainLogoStub`
                }
            } catch (e) { }
            setLender(prevState => ({
                ...prevState,
                okSubdomain,
                logo,
                name: lenderName,
                isReady: true
            }))
        })()
    }, []);


    return (
        <SubdomainLenderContext.Provider value={lender}>
            {children}
        </SubdomainLenderContext.Provider>
    );
};

export const useSubdomainLender = () => {
    const context = useContext(SubdomainLenderContext)
    if (context === undefined) {
        throw new Error('useLender must be used within a LenderProvider')
    }
    return context
}

export const LenderConsumer = SubdomainLenderContext.Consumer;
